<template>
  <b-modal
      :id="deleteModalName"
      :ref="deleteModalName"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.deleteItem.title') }}</h3>
    </template>
    <div class="delete-icon text-center">
      <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4" v-html="$t('modals.deleteItem.message')"></div>
    </div>
    <template #modal-footer="{ close }">
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="deleteItem"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

export default {
  name: 'ModalDeleteItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    teamIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    deleteModalName() {
      let teamIndexString = '';
      if (this.teamIndex) {
        teamIndexString = `-${this.teamIndex}`;
      }
      return `delete-${this.item.type}-${this.item.id}${teamIndexString}`;
    },
  },
  methods: {
    async deleteItem() {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.$emit('delete-item', this.item);
      this.$refs[this.deleteModalName].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}
</style>
