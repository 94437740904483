var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: _vm.deleteModalName,
      attrs: {
        id: _vm.deleteModalName,
        "footer-class": "flex-nowrap",
        "no-close-on-backdrop": "",
        centered: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [_vm._v(_vm._s(_vm.$t("modals.deleteItem.title")))])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function({ close }) {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3 border-right-light-grey",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { variant: "primary", block: "" },
                  on: { click: _vm.deleteItem }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "delete-icon text-center" },
        [
          _c("b-icon", {
            staticClass: "mt-5 mb-4",
            attrs: { icon: "exclamation-triangle-fill", variant: "danger" }
          }),
          _c("div", {
            staticClass: "mt-2 mb-4",
            domProps: { innerHTML: _vm._s(_vm.$t("modals.deleteItem.message")) }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }